import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormElement, Req, SubmitButton } from "../blocks/forms";
import PrivacyPolicy from "../blocks/forms/PrivacyPolicy";
import GatsbyImage from "gatsby-image";
import ReCAPTCHA from "react-google-recaptcha";

const NewRequestDemoForm = ({ returnURL, icon, fr }) => {
  const RecaptchaKey = "6LdRTiAdAAAAAJKnd7u1oETrHAP417MWwQZsBjkJ"; //Points reCAPTCHA site Key

  const URL =
    "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D80000000LKWA";
  const { register, handleSubmit, formState } = useForm({
    mode: "onChange",
  });
  const { errors } = formState;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const isNotEmpty = value => {
    return value.trim() !== "" || null || undefined;
  };

  const submitForm = async data => {
    const recaptchaResponse = window.grecaptcha.getResponse();
    console.log(recaptchaResponse);
    if (!recaptchaResponse) {
      alert("Form not submitted.\nCaptcha must be completed.");
      return;
    }

    const captchaSettings = document.querySelector(
      'input[name="captcha_settings"]'
    );
    const timestamp = new Date().getTime();
    const settings = JSON.parse(captchaSettings.value);
    settings.ts = timestamp;
    captchaSettings.value = JSON.stringify(settings);

    document.getElementById("demo-form").submit();
  };

  useEffect(() => {
    const myInterval = setInterval(timestamp, 500);

    return () => clearInterval(myInterval);
  }, []);

  function timestamp() {
    var response = document.getElementById("g-recaptcha-response");
    var settings = document.getElementsByName("captcha_settings");
    if (!response || !settings) {
      return;
    }
    if (response == null || response.value.trim() == "") {
      var elems = JSON.parse(settings[0].value);
      elems["ts"] = JSON.stringify(new Date().getTime());
      settings[0].value = JSON.stringify(elems);
    }
  }

  // const myInterval = setInterval(timestamp, 500);

  const message = "00NC0000005VhZf";

  return (
    <>
      <form
        id="demo-form"
        action={URL}
        method="POST"
        style={{ width: "80%", margin: "auto" }}
        onSubmit={handleSubmit(submitForm)}
        noValidate
      >
        <input
          type="hidden"
          name="captcha_settings"
          value='{"keyname":"Plusgrade_Request_Demo","fallback":"true","orgId":"00D80000000LKWA","ts":""}'
        />
        <input type="hidden" name="oid" value="00D80000000LKWA" />
        <input type="hidden" name="retURL" value={`${returnURL}?submitted`} />
        <input type="hidden" name="lead_source" value="Web Lead Form" />
        <select id="recordType" name="recordType" hidden>
          <option value="0123b0000007zhT" selected>
            Solutions Lead Record
          </option>
        </select>

        <FormElement onWhite>
          <label htmlFor="first_name">
            {fr ? "Prénom" : "First Name"}
            <Req />
          </label>
          <input
            id="first_name"
            maxLength="40"
            name="first_name"
            placeholder={fr ? "Prénom" : "First Name"}
            size="20"
            type="text"
            {...register("first_name", {
              required: fr
                ? "Un prénom est requis."
                : "A first name is required.",
              pattern: {
                value: /^[a-zA-Z\s\-']+$/,
                message: fr
                  ? "Le prénom est invalide."
                  : "First name is not valid.",
              },
              validate: firstName =>
                isNotEmpty(firstName) ||
                (fr ? "Un prénom est requis." : "A first name is required."),
            })}
          />
          <small>{errors.first_name?.message}</small>
        </FormElement>

        <FormElement onWhite>
          <label htmlFor="last_name">
            {fr ? "Nom" : "Last Name"}
            <Req />
          </label>
          <input
            id="last_name"
            className="last_name"
            maxLength="80"
            name="last_name"
            size="20"
            placeholder={fr ? "Nom" : "Last Name"}
            type="text"
            {...register("last_name", {
              required: fr
                ? "Un nom de famille est requis."
                : "A last name is required.",
              pattern: {
                value: /^[a-zA-Z\s\-']+$/,
                message: fr
                  ? "Le nom est invalide."
                  : "Last name is not valid.",
              },
              validate: lastName =>
                isNotEmpty(lastName) ||
                (fr
                  ? "Un nom de famille est requis."
                  : "A last name is required."),
            })}
          />
          <small>{errors.last_name?.message}</small>
        </FormElement>

        <FormElement onWhite>
          <label htmlFor="email">
            {fr ? "Courriel d'entreprise" : "Business Email"}
            <Req />
          </label>
          <input
            id="email"
            className="email"
            maxLength="80"
            name="email"
            placeholder={fr ? "Courriel d'entreprise" : "Business Email"}
            size="20"
            type="text"
            {...register("email", {
              required: fr
                ? "Adresse email invalide."
                : "An email is required.",
              pattern: {
                value: /^([a-z.%+-]+)@([a-z-]+\.)+([a-z]{2,})$/,
                message: fr ? "Adresse email invalide." : "Email is invalid.",
              },
              validate: email => {
                let regex = /gmail|yahoo|hotmail|icloud/;
                return (
                  !regex.test(email) ||
                  (fr
                    ? "Veuillez entrer votre adresse e-mail professionnelle. Les adresses e-mail personnelles telles que Gmail, Hotmail et Yahoo ne sont pas acceptées."
                    : "Please enter your business email address. Personal email addresses such as Gmail, Hotmail and Yahoo are not accepted.")
                );
              },
            })}
          />
          <small>{errors.email?.message}</small>
        </FormElement>

        <FormElement onWhite>
          <label htmlFor="company">
            {fr ? "Compagnie" : "Company"}
            <Req />
          </label>
          <input
            id="company"
            className="company"
            maxLength="40"
            name="company"
            placeholder={fr ? "Compagnie" : "Company"}
            size="20"
            type="text"
            {...register("company", {
              required: fr
                ? "Un nom d’entreprise est requis."
                : "A company name is required.",
              pattern: {
                value: /^[a-zA-Z0-9]+/,
                message: fr
                  ? "La compagnie est invalide."
                  : "Company is not valid.",
              },
              validate: company =>
                isNotEmpty(company) ||
                (fr
                  ? "Un nom d’entreprise est requis."
                  : "A company name is required."),
            })}
          />
          <small>{errors.company?.message}</small>
        </FormElement>

        <FormElement onWhite>
          <label htmlFor="phone">{fr ? "Téléphone" : "Phone"}</label>
          <input
            id="phone"
            className="phone"
            maxLength="40"
            name="phone"
            placeholder={fr ? "Téléphone" : "Phone"}
            size="20"
            type="text"
            {...register("phone", {
              pattern: {
                value: /^(?=.*[0-9])[- +()0-9]+$/,
                message: fr
                  ? "Le numéro de téléphone est invalide."
                  : "Phone number invalid.",
              },
            })}
          />
          <small>{errors.phone?.message}</small>
        </FormElement>

        <FormElement onWhite>
          <label htmlFor={"00NC0000005VhZf"}>
            {fr ? "Autre chose?" : "Anything else?"}
          </label>
          <textarea
            style={{ height: 150 }}
            id="00NC0000005VhZf"
            name="00NC0000005VhZf"
            placeholder={
              fr
                ? "Dites-nous plus sur vos objectifs de revenus auxiliaires ou vos projets."
                : "Tell us more about your ancillary revenue goals or project."
            }
            rows="3"
            type="text"
            wrap="soft"
            {...register("00NC0000005VhZf", {
              pattern: {
                value: /^[\w\s\d,.!?'"():;-]+/,
                message: "Message is not valid.",
              },
            })}
          />
        </FormElement>
        <small>{errors[message]?.message}</small>

        <FormElement onWhite>
          <div class="g-recaptcha" data-sitekey={RecaptchaKey}></div>
        </FormElement>

        <PrivacyPolicy
          policy={
            fr
              ? "En cliquant sur “Soumettre”, vous acceptez d'envoyer votre adresse e-mail à Plusgrade afin qu'ils puissent vous contacter concernant la demande ci-dessus. Plusgrade Inc. s'engage à utiliser vos informations conformément à leur "
              : "By clicking “Submit”, you agree to send your email address to Plusgrade so they may contact you about the above request. Plusgrade Inc. agrees to use your info in accordance with their "
          }
          link={
            fr
              ? "https://www.plusgrade.com/fr/politique/politique-de-confidentialite/"
              : "https://www.plusgrade.com/policy/privacy/"
          }
          clickableText={
            fr ? "politique de confidentialité." : "privacy policy."
          }
        ></PrivacyPolicy>

        <SubmitButton type={"submit"} name="btnSubmit" value={"Submit"}>
          <span className="button-text">{fr ? "Soumettre" : "Submit"}</span>
          <GatsbyImage fixed={icon.childImageSharp.fixed} />
        </SubmitButton>
      </form>
    </>
  );
};

export default NewRequestDemoForm;
